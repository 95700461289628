.location-container{
    max-width: 27%;
    width: 27%;
    font-size: 1em;
}

.location-name-container{
    display: flex;
    font-size: 1em;
}

.location-city-container{
    font-size: .7em;
    margin-left: 1.2%;
    color: gray;
}

.location-arrow-image{
    height: .9em;
    margin-top: .6%;
}

.location-place{
    font-size: .8em;
}

@media screen and (max-width: 1024px){
    .location-place{
        font-size: .7em;
    }
    .location-city-container{
        font-size: .7em;
    }
    .location-arrow-image{
        height: .8em;
    }
}

@media screen and (max-width: 767px){
    .location-city-container{
        font-size: .6em;
    }
    .location-place{
        font-size: .6em;
    }
    .location-arrow-image{
        height: .7em;
    }
}

@media screen and (max-width: 480px){
    .location-city-container{
        font-size: .5em;
    }
    .location-place{
        font-size: .5em;
    }
    .location-arrow-image{
        height: .6em;
    }
}

@media screen and (max-width: 320px){
    .location-city-container{
        font-size: .35em;
    }
    .location-place{
        font-size: .3em;
    }
    .location-arrow-image{
        height: .5em;
    }
}


