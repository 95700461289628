#gamblers-list li{
    list-style-type: none;
}

.list-container{
    width: 100%;
    max-width: 100%;
    display: inherit;
}

.list-container ul{
    padding-inline-start: 0;
    margin-block-start: 0;
}
