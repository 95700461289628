.large-form-square{
    max-width: 100%;
    width: 95%;
    margin-left: 2%;
    margin-right: 2%;
}

.medium-form-square{
    max-width: 50%;
    width: 46%;
    margin-left: 2%;
    margin-right: 1%;
}

.small-form-square{
    max-width: 33%;
    width: 29%;
    margin-left: 2%;
    margin-right: 2%;
}


@media screen and (max-width: 1024px){
    .large-form-square,.medium-form-square,.small-form-square{
        font-size: .85em;
    }

}

@media screen and (max-width: 767px){
    .large-form-square,.medium-form-square,.small-form-square{
        font-size: .8em;
    }
}

@media screen and (max-width: 480px){
    .form-container-dev{
        height: 400px;
    }
    .large-form-square,.medium-form-square,.small-form-square{
        font-size: .7em;
    }
}

@media screen and (max-width: 320px){
    .form-container-dev{
        height: 450px;
    }
    .large-form-square,.medium-form-square,.small-form-square{
        font-size: .65em;
    }
}
