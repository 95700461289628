.form-container-dev {
    background: #FFFFFF;
    box-shadow: 0px 8px 15px 0 #01afd2;
    border-radius: 8px 8px 8px 8px;
    margin: 5% 5% 5% 10%;
    display: flex;
    width: 80%;
    max-width: 100%;
    flex-wrap: wrap;
    height: 450px;
}

#button{
    justify-content: center;
    display: flex;
    margin-bottom: 5%;
}

#button .btn-primary{
    /*background-color: #979797;*/
    /*border-color: #9d9d9d;*/
}


@media screen and (max-width: 1024px){
}

@media screen and (max-width: 767px){
}

@media screen and (max-width: 480px){
    .form-container-dev{
        height: 400px;
    }
}

@media screen and (max-width: 320px){
    .buttons-container .btn-primary{
        font-size: 3em;
    }
}
