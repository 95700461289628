.title-container{
    width: 100%;
    max-width: 100%;
    margin: 0 20% 0 20%;
    font-size: 2.2em;
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 1024px){
    .title-container{
        font-size: 1.7em;
    }
}

@media screen and (max-width: 767px){
    .title-container{
        font-size: 1.4em;
    }
}

@media screen and (max-width: 480px){
    .title-container{
        font-size: 1em;
    }
}

@media screen and (max-width: 320px){
    .title-container{
        font-size: 1em;
    }
}
