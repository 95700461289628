.titles-container{
    width: 100%;
    max-width: 100%;
    display: flex;
    font-size: 1em;
    border-color: #4e61af;
    border-radius: 5px 5px 5px 5px;
    border-style: solid;
    border-width: .16em;
    position: sticky;
    background: #6382ff;
    color: #ffffff;
    top: 0;
}

.photo-title{
    width: 10%;
    font-weight: bold;
    font-size: .8em;
    justify-content: center;
    display: flex;
}

#titles{
    font-size: .8em;
}


@media screen and (max-width: 1024px){
    #titles{
        font-size: .75em;
    }
}

@media screen and (max-width: 767px){

    #titles{
        font-size: .7em;
    }
}

@media screen and (max-width: 480px){

    #titles{
        font-size: .6em;
    }
}

@media screen and (max-width: 320px){

    #titles{
        font-size: .4em;
    }
}
