.gambler-container{
    width: 100%;
    max-width: 100%;
    display: flex;
    font-size: 1em;
    border-color: #080814;
    border-width: 1px;
    border-style: solid;
}


.photo-container{
    max-width: 10%;
    max-height: 80px;
    height: 80px;
    flex: 1;
    display: flex;
    justify-content: center;
}
.photo{
    height: 80%;
    width: 60%;
    margin-top: 4%;
    display: flex;
    justify-content: center;
    border-color: #7a7a7a;
    border-radius: 15px 15px 15px 15px;
    border-style: solid;
}

.numRegis{
    max-width: 10%;
    width: 10%;
    font-size: .7em;
}

.person{
    /*padding-left: 1%;*/
    max-width: 33%;
    width: 33%;
    font-size: .75em;
    font-weight: bold;
}

.location{
    max-width: 27%;
    width: 27%;
    font-size: .8em;
}

.date{
    max-width: 8%;
    width: 8%;
    font-size: .8em;
}

.gambler-container .container-fluid{
    max-width: 12%;
    width: 12%;
    padding-right: 0;
    padding-left: 0;
}

.gambler-container .container-fluid .btn-primary{
    font-size: 1rem;
    /*background-color: #979797;*/
    /*border-color: #9d9d9d;*/
}



@media screen and (max-width: 1024px){
    .date{
        font-size: .7em;
    }
    .person{
        font-size: .7em;
    }
    .numRegis{
        font-size: .65em;
    }
    .location{
        font-size: .8em;
    }
    .gambler-container .container-fluid .btn-primary{
        font-size: .8rem;
    }
}

@media screen and (max-width: 767px){
    .numRegis{
        font-size: .5em;
    }
    .person{
        font-size: .6em;
    }
    .location{
        font-size: .6em;
    }
    .photo-container{
        max-height: 60px;
    }
    .date{
        font-size: .6em;
    }
    #titles{
        font-size: .7em;
    }
    .gambler-container .container-fluid .btn-primary{
        font-size: .3rem;
    }
}

@media screen and (max-width: 480px){
    .location,.numRegis,.person{
        font-size: 0.5em;
    }
    .photo-container{
        max-height: 50px;
    }
    #titles{
        font-size: .6em;
    }

    .date{
        font-size: .45em;
    }
    .gambler-container .container-fluid .btn-primary{
        font-size: .3rem;
    }
}

@media screen and (max-width: 320px){
    .date,.location,.person{
        font-size: .4em;
    }
    .numRegis{
        font-size: .3em;
    }
    .photo-container{
        max-height: 40px;
    }
    #titles{
        font-size: .4em;
    }
    .gambler-container .container-fluid .btn-primary{
         font-size: .2rem;
    }
}
