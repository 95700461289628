.person-container{
    max-width: 33%;
    width: 33%;
    font-size: 1em;
    font-weight: bold;
}

.person-name-container{
    font-size: .75em;
}

.person-id-container{
    display: flex;
}

.person-id-image{
    height: 1.5em;
}

.person-id{
    font-size: .8em;
}


@media screen and (max-width: 1024px){
    .person-id{
        font-size: .7em;
    }
    .person-name-container{
        font-size: .7em;
    }
    .person-id-image{
        height: 1.2em;
    }
}

@media screen and (max-width: 767px){
    .person-name-container{
        font-size: .6em;
    }
    .person-id{
        font-size: .6em;
    }
    .person-id-image{
        height: 1em;
    }
}

@media screen and (max-width: 480px){
    .person-name-container{
        font-size: .5em;
    }
    .person-id{
        font-size: .5em;
    }
    .person-id-image{
        height: .7em;
    }
}

@media screen and (max-width: 320px){
    .person-name-container{
        font-size: .35em;
    }
    .person-id{
        font-size: .3em;
    }
    .person-id-image{
        height: .6em;
    }
}
